import React, { useState, useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import Plane from "./Plane";
import "./Aviator.css";
import Chatbox from "../SlotMachine/Chatbox";
import { useDispatch, useSelector } from "react-redux";
import { Fetch_Aviator_Game_Data } from "../../store/Actions/GameActions";

function Aviator() {
  const initialWalletAmount = 1000; // Initial wallet amount
  const [multiplier, setMultiplier] = useState(0);
  const [betAmount, setBetAmount] = useState(0);
  const [walletAmount, setWalletAmount] = useState(initialWalletAmount);
  const [isGameRunning, setIsGameRunning] = useState(false);
  const [isCrashed, setIsCrashed] = useState(false);
  const [showCrashMessage, setShowCrashMessage] = useState(false);
  const [showCashOutMessage, setShowCashOutMessage] = useState(false);
  const [crashMessage, setCrashMessage] = useState("");
  const [cashOutMessage, setCashOutMessage] = useState("");
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [{ label: "", data: [] }],
  });
  const [isAutomatedRunning, setIsAutomatedRunning] = useState(false); // State to control the loop

  const intervalRef = useRef(null);
  const hasCashedOut = useRef(false); // To track if the player has cashed out
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    // If the game is running and the automated mode is on, check wallet before restarting the game
    if (isAutomatedRunning && !isGameRunning && walletAmount >= betAmount) {
      // Ensure that wallet amount is updated before restarting the game
      const timeoutId = setTimeout(() => {
        startGame();
      }, 5000); // 5 seconds delay before restarting the game

      return () => clearTimeout(timeoutId);
    }
  }, [walletAmount, isGameRunning, isAutomatedRunning, betAmount]);

  const updateChartData = (time, multiplier) => {
    setChartData((prevData) => {
      const labels = [...prevData.labels, time.toFixed(1)];
      const data = [...prevData.datasets[0].data, multiplier.toFixed(2)];
      return {
        labels: labels,
        datasets: [
          {
            label: "",
            data: data,
            fill: false,
            borderColor: "rgba(0, 0, 0, 0)", // Transparent color for the line
            cubicInterpolationMode: "monotone",
            pointRadius: 0, // Remove points (dots)
          },
        ],
      };
    });
  };

  const startGame = () => {
    console.log(topOption, betAmount, walletAmount, "Game started");

    if (betAmount > 0 && betAmount <= walletAmount) {
      setIsAutomatedRunning(topOption === 1); // Set automated mode based on topOption
      setIsGameRunning(true);
      setIsCrashed(false);
      hasCashedOut.current = false;
      setMultiplier(0);
      setChartData({ labels: [], datasets: [{ label: "", data: [] }] });

      // Decrease wallet amount
      setWalletAmount((prevWalletAmount) => prevWalletAmount - betAmount);

      // Simulate the game process
      const crashTime = Math.random() * 10000 + 1000; // Plane crashes randomly between 1 and 10 seconds
      let startTime = Date.now();

      intervalRef.current = setInterval(() => {
        const timeElapsed = (Date.now() - startTime) / 1000; // in seconds
        const newMultiplier = timeElapsed * timeElapsed * 0.01; // Quadratic function
        setMultiplier(newMultiplier);
        updateChartData(timeElapsed, newMultiplier);
      }, 100);

      // Simulate the crash after the plane has flown for a random duration
      setTimeout(() => {
        setIsCrashed(true);
        setIsButtonDisabled(false);
        setIsGameRunning(false);
        clearInterval(intervalRef.current);

        if (!hasCashedOut.current) {
          setCrashMessage("Plane crashed.");
          setShowCrashMessage(true);

          setTimeout(() => {
            setShowCrashMessage(false);
            setIsCrashed(false);
            setMultiplier(0); // Reset multiplier after crash
          }, 3000);
        }
      }, crashTime);
    } else {
      alert(
        "Insufficient funds or invalid bet amount. Please enter a valid bet amount."
      );
    }
  };

  const stopAutomatedGame = () => {
    setIsAutomatedRunning(false); // Stop the loop
    setIsGameRunning(false); // Stop the game
  };

  const cashOut = () => {
    if (isGameRunning) {
      setIsButtonDisabled(true);

      const winnings = betAmount * multiplier;
      const newWalletAmount = walletAmount + betAmount + winnings;

      setCashOutMessage(
        `You cashed out at ${multiplier.toFixed(
          2
        )}x and won: ${winnings.toFixed(2)}`
      );
      setWalletAmount(newWalletAmount);
      hasCashedOut.current = true;

      // Show cash out message
      setShowCashOutMessage(true);

      setTimeout(() => {
        setShowCashOutMessage(false);
        setMultiplier(0);

        // Clear crash message if visible
        if (isCrashed) {
          setCrashMessage("Oops! Your plane crashed after cashing out.");
          setShowCrashMessage(true);
          setIsCrashed(false); // Reset crash state
        }
      }, 2000);
    }
  };

  const [topOption, setTopOption] = useState(0);
  const [selectOption, setSelectOption] = useState();
  const options = ["1/2", "2x", "CLR"];

  const handleTopOption = (index) => {
    setTopOption(index);
  };
  const handleSelectOption = (index) => {
    setSelectOption(index);
  };

  useEffect(() => {
    console.log(selectOption, "------------selectionOption");
    switch (selectOption) {
      case 0:
        console.log(selectOption, " -------------------selectOption");
        setBetAmount(betAmount / 2);
        break;
      case 1:
        setBetAmount(betAmount * 2);
        break;
      case 2:
        setBetAmount(0);
        break;
      default:
        // Optionally, handle cases where selectOption is not 0, 1, or 2
        break;
    }
  }, [selectOption]); // Make sure to include dependencies

  return (
    <div className="aviator_container w-100 d-flex aviator-container">
      <div className="Aviator_gamebox1">
        <div className="innerBox">
          <div className="topBtn">
            {topOption === 1 && isAutomatedRunning && (
              <button
                className="a-btn"
                onClick={stopAutomatedGame}
                disabled={!isAutomatedRunning}
              >
                Stop Automated Game
              </button>
            )}
            <button
              className={`btn SelctedBtn ${topOption === 0 ? "colorChng" : ""
                }`}
              onClick={() => handleTopOption(0)}
            >
              Manual
            </button>
            <button
              className={`btn SelctedBtn ${topOption === 1 ? "colorChng" : ""
                }`}
              onClick={() => handleTopOption(1)}
            >
              Automated
            </button>
          </div>
          <div>
            <form className="rocketForm gaprocket">
              <div className="form-group" style={{ width: "100%" }}>
                <label className="pb-2">Bet Amount</label>
                <input
                  className="form-control"
                  type="number"
                  value={betAmount}
                  onChange={(e) => setBetAmount(parseFloat(e.target.value))}
                  placeholder="Enter bet amount"
                  disabled={isGameRunning}
                />
              </div>
              <div className="options" style={{ width: "100%" }}>
                <div className="d-flex justify-content-between">
                  {options.map((item, index) => (
                    <button
                      key={index}
                      className={`btn slctOptn ${selectOption === index ? "yellowBg" : ""
                        }`}
                      onClick={(event) => {
                        event.preventDefault();
                        handleSelectOption(index);
                      }}
                    >
                      {item}
                    </button>
                  ))}
                </div>
              </div>
              <div className="form-group" style={{ width: "100%" }}>
                <label className="pb-2">Cashout at</label>
                <input
                  className="form-control"
                  type="text" // Changed to "text" since we want to show "x" after the number
                  value={`${cashOutMessage}`} // Reflects the multiplier dynamically
                  readOnly // Make the field read-only since it is auto-updated and not user-editable
                  placeholder="Cashout Amount"
                />
              </div>
              <button
                className="a-btn"
                onClick={cashOut}
                disabled={!isGameRunning || isButtonDisabled}
              >
                Cash Out
              </button>
              <div className="form-group" style={{ width: "100%" }}>
                <label className="pb-2">Total Payout</label>
                <input
                  className="form-control"
                  value={walletAmount.toFixed(2)}
                  readOnly
                />
              </div>
              <button
                className={
                  `a-start-btn w-100 ${!isGameRunning ? "backgreen" : ""}`
                }
                // style={{ backgroundColor: "#3eb13b !important" }}
                onClick={startGame}
                disabled={isGameRunning}
              >
                Bet
              </button>
              <div className="contentdiv_rocket">
                <h6
                  className="contentdiv_rocket_h6"
                // style={{
                //   textAlign: "start",
                //   width: "60%",
                //   fontSize: "13px",
                //   display: "flex",
                //   justifyContent: "space-between",
                // }}
                >
                  Hidden <span style={{ color: "#FDBE1B" }}>0.082456440</span>
                </h6>
                <h6
                  className="contentdiv_rocket_h6"
                // style={{
                //   textAlign: "start",
                //   width: "60%",
                //   fontSize: "13px",
                //   display: "flex",
                //   justifyContent: "space-between",
                // }}
                >
                  VersaceTLE{" "}
                  <span style={{ color: "#FDBE1B" }}>OSRS 84.92M</span>
                </h6>
                <h6
                  className="contentdiv_rocket_h6"
                // style={{
                //   textAlign: "start",
                //   width: "60%",
                //   fontSize: "13px",
                //   display: "flex",
                //   justifyContent: "space-between",
                // }}
                >
                  Drain_Fluid{" "}
                  <span style={{ color: "#FDBE1B" }}>OSRS 40M</span>
                </h6>
                <h6
                  className="contentdiv_rocket_h6"
                // style={{
                //   textAlign: "start",
                //   width: "60%",
                //   fontSize: "13px",
                //   display: "flex",
                //   justifyContent: "space-between",
                // }}
                >
                  Miraclebets{" "}
                  <span style={{ color: "#FDBE1B" }}>OSRS 15M</span>
                </h6>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="AppAviator">
        <div className="game-board">
          <div className="background">
            <Plane isCrashed={isCrashed} isGameRunning={isGameRunning} />
          </div>
          <div className="multiplier">{multiplier.toFixed(2)}x</div>
          <div className="chart-container">
              <img src={'/images/aviator-bg.png'} alt="background"/>

            <Line
              data={chartData}
              style={{
                minHeight: "100%",
                padding: '60px 70px 25px 25px'
              }}
              options={{
                animation: {
                  duration: 0, // Disable animation to sync plane with chart
                },
                elements: {
                  line: {
                    tension: 0.4, // Smooth curve
                    borderWidth: 2, // Increased line thickness
                    fill: "none", // Remove line fill (for transparency)
                    borderColor: "white",
                    z: 10, // Move line to foreground
                  },
                  gridLines: {
                    color: "rgba(255, 255, 255, 0.2)", // Semi-transparent white grid lines (optional)
                  },
                  point: {
                    radius: 0, // Remove points (dots)
                  },
                },
                scales: {
                  x: {
                    display: true,
                    type: "linear",
                    position: "bottom",
                    grid: {
                      display: false, // Hide grid lines
                      borderColor: "white", // White border for x-axis
                    },
                    ticks: {
                      color: "white", // White labels for x-axis
                    },
                  },
                  y: {
                    display: true,
                    beginAtZero: true, // Ensure the line starts from 0
                    grid: {
                      display: false, // Hide grid lines
                      borderColor: "white", // White border for y-axis
                    },
                    ticks: {
                      color: "white", // White labels for y-axis
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
            />
          </div>
        </div>
        {showCrashMessage && (
          // <div className="aviator-message-container">{crashMessage}</div>
          <div className="aviator-message-container"></div>
        )}
        {showCashOutMessage && (
          <div className="slot-message-container">{cashOutMessage}</div>
        )}
      </div>

      <div
        className="mx-100 chatbox_min"
        style={{
          height: "100%",
          margin: "0px",
        }}
      >
        <Chatbox />
      </div>
    </div>
  );
}

export default Aviator;
