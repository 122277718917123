import React from 'react'
import '../../Styles/CasinoPage.css'
import Casinosection1 from '../Pages/Casinosection1'
import Casinosection2 from '../Pages/Casinosection2'
import Casinosection3 from '../Pages/Casinosection3'

function CasinoPage() {
  return (
    <>
      <div>
        <Casinosection1 />
        <Casinosection2 />
        <Casinosection3 />
      </div>
    </>
  )
}

export default CasinoPage
