import React from 'react'
import Accountsection1 from '../Pages/account/Accountsection1'
import '../../Styles/Account.css'
import Accountsection2 from '../Pages/account/Accountsection2'
import Accountsection3 from '../Pages/account/Accountsection3'

function Account() {
  return (
    <>
      <Accountsection1 />
      <Accountsection2 />
      <Accountsection3 />
    </>
  )
}

export default Account
